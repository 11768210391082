import { createHttpClient } from "@www/utils";
import * as amplitude from "@amplitude/analytics-browser";
import { browser, dev } from "$app/environment";
import { getCookieYesConsent } from "../utils/get-cookie-yes-consent";
export const createAnalyticsService = (amplitude_api_key, api_url, devMode) => {
    log("Creating Analytics Service");
    const http = createHttpClient(api_url);
    const deleteAmplitudeCookies = () => {
        if (!browser)
            return;
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === " ") {
                cookie = cookie.substring(1);
            }
            if (cookie.startsWith("AMP_")) {
                const cookieName = cookie.split("=")[0];
                document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
            }
        }
    };
    return {
        initialize: async () => {
            if (!browser)
                return;
            const cookieYesConsent = getCookieYesConsent("analytics");
            const analyticsConsent = devMode || cookieYesConsent;
            if (!analyticsConsent)
                return;
            const url = window.location;
            const options = {
                defaultTracking: false,
                transport: "beacon",
                logLevel: dev ? amplitude.Types.LogLevel.Verbose : amplitude.Types.LogLevel.Warn,
                serverZone: "US",
                serverUrl: `${url.protocol}//${url.host}/amplitude`,
                userId: undefined,
                optOut: false,
            };
            try {
                // Check if we are logged in
                const userResponse = await http.get("/user/profile").setCredentials("include").send();
                const profile = (await userResponse.json());
                const sessionResponse = await http.get("/auth/session").setCredentials("include").send();
                const session = (await sessionResponse.json());
                options.userId = profile.id;
                options.sessionId = session.id;
                options.optOut = !profile.collectAnalytics;
                if (!profile.collectAnalytics) {
                    deleteAmplitudeCookies();
                }
                log("Initializing Amplitude as User");
            }
            catch (_err) {
                log("Initializing Amplitude as Anonymous");
            }
            amplitude.init(amplitude_api_key, options);
        },
        reinitialize: async () => {
            if (!browser)
                return;
            let profile;
            try {
                // Check if we are logged in
                const response = await http.get("/user/profile").setCredentials("include").send();
                profile = (await response.json());
            }
            catch (_err) {
                // Do nothing
            }
            if (profile) {
                amplitude.setUserId(profile.id);
                amplitude.setOptOut(!profile.collectAnalytics);
                log("Reinitializing Amplitude as User");
                if (!profile.collectAnalytics) {
                    log("User opted out of tracking!");
                    deleteAmplitudeCookies();
                }
            }
            else {
                deleteAmplitudeCookies();
                amplitude.setUserId(undefined);
                amplitude.setOptOut(false);
                log("Reinitializing Amplitude as Anonymous");
            }
        },
    };
};
const log = (message) => {
    if (dev)
        console.log(message);
};
